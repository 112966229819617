@import '../../scss/default/color_variable';
.cs-iconbox_3_list {
  position: relative;
  padding-left: 550px;
  min-height: 640px;
  > *:not(:last-child) {
    border-bottom: 1px solid #4d4d4d;
  }
  .cs-image_layer {
    position: absolute;
    left: 0;
    top: 25px;
    pointer-events: none;
    max-width: 416px;
    height: 600px;
    .cs-image_layer_in {
      height: 100%;
      width: 100%;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: relative;
      clip-path: inset(0 100% 0 0);
      animation: inactiveImageAnimation1 0.6s forwards ease;
    }
  }
  .cs-hover_tab{
    width: 100%;
    transition: all 1s;
    .cs-iconbox_icon{
      transition: transform 4s;

    }
    &:hover{
      // transform: scaleX(1.5);
      // width: ;
      width: 150%;
      margin-left: -14%;
      .cs-iconbox_icon{
        transform: rotate(43deg);
      }
    }
  }
  .cs-hover_tab.active {
    .cs-image_layer {
      img {
        animation: activeImageAnimation1 0.6s forwards ease;
      }
    }
    .cs-iconbox_icon {
      color: #fff;
      background-color: $accent;
      border-color: $accent;
    }
  }
  @media (max-width: 1199px) {
    padding-left: 460px;
  }
  @media (max-width: 991px) {
    padding-left: 0;
    padding-top: 650px;
    .cs-image_layer {
      max-width: 100%;
      width: 100%;
    }
  }
  &.cs-style1 {
    padding-left: 0;
    min-height: initial;
    .cs-iconbox.cs-style3 {
      .cs-iconbox_in {
        max-width: 560px;
      }
    }
    @media (max-width: 1199px) {
      padding: 0;
    }
  }
  &.cs-style2 {
    padding-left: 110px;
    counter-reset: number-counter;
    padding-top: 0;
    @media (max-width: 991px) {
      padding-left: 40px;
    }
    @media (max-width: 575px) {
      padding-left: 0px;
    }
    .cs-image_layer.cs-style1,
    .cs-image_layer_in::after {
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
    }
    .cs-image_layer.cs-style1 {
      padding-right: 0;
      padding-bottom: 0;
      height: initial;
      width: 170px;
      transform: rotate(0deg) translateY(-50%);
      top: 50%;
      right: 28%;
      left: initial;
      @media (max-width: 1199px) {
        right: 20%;
      }
      @media (max-width: 991px) {
        display: none;
      }
      img {
        animation: none;
        clip-path: inset(0% 0% 0 0);
      }
    }
    .cs-hover_tab {
      position: relative;
      &::before {
        counter-increment: number-counter;
        content: counter(number-counter, decimal-leading-zero) '. ';
        font-weight: 600;
        color: #fff;
        position: absolute;
        left: -110px;
        top: 30px;
        @media (max-width: 991px) {
          left: -40px;
        }
        @media (max-width: 575px) {
          display: none;
        }
      }
      &.active {
        .cs-image_layer.cs-style1 {
          opacity: 1;
          visibility: visible;
          transform: rotate(15deg) translateY(-50%);
        }
      }
    }
  }
}
.cs-iconbox.cs-style3 {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-bottom: 25px;
  padding-top: 25px;
  color: $secondary;
  .cs-iconbox_in {
    margin-right: 30px;
    max-width: 480px;
  }
  .cs-iconbox_icon {
    color: $ternary;
    border: 2px solid $ternary;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    flex: none;
    transition: all 0.3s ease;
    &:hover {
      color: #fff;
      background-color: $accent;
      border-color: $accent;
    }
  }
  .cs-iconbox_title {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .cs-iconbox_subtitle {
    line-height: 1.75em;
  }
  &:hover {
    .cs-iconbox_icon {
      color: #fff;
      background-color: $accent;
      border-color: $accent;
    }
  }
  @media (max-width: 991px) {
    .cs-iconbox_title {
      font-size: 26px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    .cs-iconbox_icon {
      position: initial;
      transform: initial;
      margin-bottom: 20px;
      height: 60px;
      width: 60px;
      svg {
        max-height: 22px;
      }
    }
  }
}
@keyframes inactiveImageAnimation1 {
  0% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 100% 0 0);
  }
}
@keyframes activeImageAnimation1 {
  0% {
    clip-path: inset(0 0 0 100%);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}
