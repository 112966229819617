.sidebar-main{
    position: fixed;
    right: 1%;
    top: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    z-index: 100;
    .navigation-item{
        height: 2rem;
        width: 2rem;
        svg{
            path{
                fill: green;
            }
        }
    }
}